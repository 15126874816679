import React from "react";
import imgTitle from '../../../assets/images/section05/title.png';
import imgTitleSub from '../../../assets/images/section05/title-sub.png';
import imgEarth from '../../../assets/images/section05/earth.png';
import imgQuestion from '../../../assets/images/section05/question.png';
import "./style.sass";

const Section5 = () => (
  <div className="section-05 page">
    {/* <img className="title" src={imgTitle} alt="title" /> */}
    {/* <img className="title-sub" src={imgTitleSub} alt="title-sub" /> */}
    <div>
      {/* <span className="title-sub-text">生活需要科技 質感引領未來</span> */}
      <div className="line-bottom" />
    </div>

    <div className="content-01">
      <img className="earth" src={imgEarth} alt="earth" />
      <span className="text">隨著AI時代的逐漸來臨，利用新一代資訊與通信技術來感知、監控、分析設備的需求變得日益增多了起來，許多重複繁瑣的工作也逐漸使用物聯網來操控，那麼，我們的“AIoT智慧物聯網”，到底能幫您解決什麼呢？</span>
    </div>

    <img className="img-question" src={imgQuestion} alt="question" />

    <div className="content-02">
      <div className="border">
        <div className="content">
          <span className="text">您是否想過打造一套完整的能源管理系統，需要多少錢？</span>
        </div>
      </div>
      <div className="border">
        <div className="content">
          <span className="text">您是否想過，一套能源管理系統從設計到程式設計，再到後期維護改進，需要多少時間？</span>
        </div>
      </div>
      <div className="border">
        <div className="content">
          <span className="text">您是否想過，添加新的設備，現有的能源管理系統能相容嗎？</span>
        </div>
      </div>
    </div>
  </div>
)

export default Section5;
