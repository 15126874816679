import React from "react";
import "./zigvideo.sass";
import imgOneClick from '../../../assets/images/section09/oneClick.png';
import IconBlock from './IconBlcok';
import img001 from '../../../assets/images/section04/046.png';
import icon01 from '../../../assets/images/icon/icon apps-add-01.png';
import icon02 from '../../../assets/images/icon/icon snap_15398582-01.png';
import icon03 from '../../../assets/images/icon/icon robot_11747164-01.png';
import svg001 from '../../../assets/images/icon/icon apps-add.svg';
import svg002 from '../../../assets/images/icon/icon snap_15398582.svg';
import svg003 from '../../../assets/images/icon/icon robot_11747164.svg';

class ZigVideo extends React.PureComponent {
  render() {

    const iconArray = [
      {
        icon: img001,
        title: "台電DREAMS",
        description: "",
      },
      {
        icon: img001,
        title: "PLC",
        description: "西門子, 三菱, 歐姆龍等全系列PLCS",
      },
      {
        icon: img001,
        title: "OPC UA",
        description: "",
      },
      {
        icon: img001,
        title: "ESG",
        description: "碳盤查, 碳足跡",
      },
      {
        icon: img001,
        title: "EMS",
        description: "能源管理系統",
      },
    ];

    const iconArray02 = [
      {
        icon: svg001,
        title: "通用",
      },
      {
        icon: svg002,
        title: "易用",
      },
      {
        icon: svg003,
        title: "自動化",
      },
    ]

    return (
      <section
        className="zig-video"
        ref={(target) => this.props.setRef("zigVideo", target)}
      >
        <div className="wrap">
          <h1 className="title">
            單一系統就能輕鬆定制
          </h1>
          <h1 className="title">
            各種行業領域的物連網解決方案
          </h1>
          <p className="title-sub">
            無需寫程式，僅需拖拉即可完成定制的方案，ZiG助你更低成本，更快速導入並結案
          </p>

          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: 60, marginTop: 40, }}>
            {iconArray02.map(item => (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: 100, filter: 'invert(100%)' }} src={item.icon} alt={item.title} />
                <div style={{ marginTop: 20, fontSize: '2em', }}>{item.title}</div>
              </div>
            ))}
          </div>

          {/* <div className="img-oneClick-warp">
            <div className="icons-oneClick">
              {iconArray.map(item => <IconBlock icon={item.icon} title={item.title} description={item.description} />)}
            </div>
            <img className="img-oneClick" src={imgOneClick} alt="oneClick" />
          </div> */}
          <iframe
            style={{ width: '100%', height: 800, border: 'none' }}
            title="banner"
            src="http://zignet.co:88/index.php/zig-banner/"
          />

          <div className="video-container">
            <video
              id="video-zig"
              controls
              muted
              ref={(target) => this.props.setRef("zigVideoPlay", target)}
              onEnded={this.props.zigVideoEnd}
              preload="auto"
            >
              <source id="mp4-01" src={process.env.PUBLIC_URL + '/video/zig-new.mp4'} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
    );
  }
}
export default ZigVideo;
