import React from 'react';
import './index.scss';
// import Content001 from '../../Components/Content001';
import Content002 from '../../Components/Content002';
import img002 from '../../../../assets/images/section03/002.jpg';

const Section04 = (props) => {

  const textBlockList = [
    {
      index: 1,
      title: '快速對接',
      content: '接上設備後，選擇對應套件即可快速安裝啟用'
    }, {
      index: 2,
      title: '無相容性問題',
      content: '不分廠牌、不分類型，多種設備套件任意挑選'
    },
    {
      index: 3,
      title: '豐富的PLC及各種設備',
      content: '支援西門子，三菱，台達，洛克威爾，歐姆龍，FUNC等等各廠牌PLC'
    },
    {
      index: 4,
      title: '通訊協定',
      content: 'MODBUS，OPCUA，DNP3，MQTT，台電DREAMS，IEC-61850等等'
    },
    {
      index: 5,
      title: '新增支援',
      content: '中興電工，儲盈，七泰等 BMS及PCS'
    },
  ]

  const DemoItem = () => (
    <img style={{ width: '100%' }} src={img002} alt="device-center-demo" />
  )

  return (
    <div
      className="section-04"
      ref={(target) => props.setRef("section04", target)}
    >
      <Content002
        title001="設備套件中心"
        title002="DEVICE CENTER"
        title003="各大設備商產品陸續進駐對接，精選各類設備的豐富套件中心，支援超過100種品牌型號的設備、快速導入，安裝即可在平台上監控、操作"
        textBlockList={textBlockList}
        DemoItem={DemoItem}
        demoItemPosition={'right'}
        videoSrc={process.env.PUBLIC_URL + "/video/plugin-center.mp4"}
        handlePopVideoOpen={props.handlePopVideoOpen}
      />
    </div>
  )
}

export default Section04;