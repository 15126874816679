import React from 'react';
import './index.scss';
// import Content001 from '../../Components/Content001';
import Content002 from '../../Components/Content002';
import DashboardDemo from '../../../../components/DashboardDemo';

const Section02 = (props) => {

  const textBlockList = [
    {
      index: 1,
      title: '快速管理',
      content: '設備數據即時更新、設備狀態統一管理'
    }, {
      index: 2,
      title: '圖表豐富',
      content: '將設備數據轉變為豐富的可視化圖表'
    },
    {
      index: 3,
      title: '操作簡單',
      content: '自由拖拉、排版各數據圖表，呈現客製化介面'
    },
  ]

  return (
    <div
      className="section-02"
      ref={(target) => props.setRef("section02", target)}
    >
      <Content002
        title001="可視化戰情看板"
        title002="DASHBOARD"
        title003="
        自由度高的客製化介面，
        選擇想呈現的設備數據內容，
        快速掌握同時能夠優化設備運作、提升用電效率，
        並分析各系統能耗現況，
        作為評估節能改善效果的依據
        "
        textBlockList={textBlockList}
        DemoItem={DashboardDemo}
        demoItemPosition={'right'}
        videoSrc={process.env.PUBLIC_URL + "/video/dashboard.mp4"}
        handlePopVideoOpen={props.handlePopVideoOpen}
      />
    </div>
  )
}

export default Section02;