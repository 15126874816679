import React, { useState, } from "react";
import imgCross from '../../../assets/images/section06/cross.png';
import imgComputer from '../../../assets/images/section06/computer.png';
import imgNext from '../../../assets/images/section06/next.png';
import imgArrowDown from '../../../assets/images/section06/down-arrow.png';
import icon01 from '../../../assets/images/section06/1100904紫克科技網站_跨5G時代1.png';
import icon02 from '../../../assets/images/section06/1100904紫克科技網站_跨5G時代2.png';
import icon03 from '../../../assets/images/section06/1100904紫克科技網站_跨5G時代3.png';
import './style.sass';

const content01 = [
  {
    icon: icon01,
    text: '定制化系統開發週期漫長，通常需耗時幾個月甚至1年以上，而且與客戶構通慢，隨之而來的是成本的升高。'
  },
  {
    icon: icon02,
    text: '客戶想要尋找某些想要的設備時很難搜尋，可能有時候只是同類型產品的橫向對比都很難查詢。'
  }
]

const content02 = [
  {
    icon: icon01,
    text: '自己的設備無法與其他廠商的設備連動，導致競爭力下降。'
  },
  {
    icon: icon02,
    text: '從開發到測試每個設備，耗時長，客戶精力有限。'
  },
  {
    icon: icon03,
    text: '完成之後曝光度不足，無法讓更多人得知。'
  }
]



const Section6 = () => {
  const [display01, setDisplay01] = useState(false);
  const [display02, setDisplay02] = useState(false);

  const renderContent = (arr) => (
    arr.map(item =>
      <div className="item" key={String(Math.random())}>
        <img className="img-number" src={item.icon} alt='number' />
        <p className="text">{item.text}</p>
      </div>
    )
  )

  return (
    <div className="section-06 page">
      <div className="content-01">
        <span className="text">想問題的時候</span>
        <span className="text">我們接著往下看</span>
        <span className="text-en">when we think about it, we'll move on</span>
        <img className="img-arrow-down" src={imgArrowDown} alt="down-arrow" />
        <span className="description">ZiG為以下2種模式</span>
        <span className="description">提供最有效的解決方案</span>
      </div>

      <div className="content-02">
        <div className="block">
          <img src={imgCross} alt="cross" />
          <div className="title">系統整合商</div>
          <p className="title-sub">系統整合商是為客戶提供物聯網構建服務，而在資訊時代高速發展的今天，開發時間越長，代表他被社會淘汰的速度越快，畢竟他可能在沒開發完全之前就很快被新的東西取代，他們的問題在於：</p>
          {display01 ?
            renderContent(content01)
            :
            <button
              className="btn-more"
              id="btn-more-info-system-combine"
              onClick={() => setDisplay01(true)}
            >
              了解更多
            </button>
          }
        </div>
        <div className="block">
          <img src={imgComputer} alt="computer" />
          <div className="title">設備商</div>
          <p className="title-sub">設備商是為客戶即時回饋產品的資訊，包括設備更新升級，與系統的相容性等情況，當然這僅限於自家廠商的東西。而不同廠商的東西通常連動很困難，導致客戶選擇猶豫、困難，最終棄選，他們的問題在於：</p>
          {display02 ?
            renderContent(content02)
            :
            <button
              className="btn-more"
              id="btn-more-info-device-provider"
              onClick={() => setDisplay02(true)}
            >
              了解更多
            </button>
          }
        </div>
      </div>

      <div className="content-03">
        <span className="text">看完上面的問題，你肯定會在想</span>
        <span className="text">這些問題我都知道</span>
        <span className="text">這跟你們的物聯網有什麼關係呢？</span>
        <span className="text">想知道答案</span>
        <span className="text">看看我們的物聯網你就知道</span>
        <img src={imgNext} alt="next" />
      </div>
    </div>
  )
}

export default Section6;